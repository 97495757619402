import React, { useRef, useEffect, useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import { openNotification } from "../../helpers/openNotification";
import { apiUrlBase } from "../../data/urls";
import "./ImagePicker.css";
import { connect } from "react-redux";
import { AuthState } from "../../store/auth/types";
import { PlansState } from "../../store/plans/types";
import { SharedAccessesState } from "../../store/sharedAccesses/types";
import { UserState } from "../../store/user/types";
import { GetCurrentStoreData } from "../../store/websites/types";
import _ from "lodash";
import { checkIsProPlan } from "../../helpers/user/checkIsProPlan";
import isWebsiteSharedWithCurrentUser from "../../helpers/shareAccess/isWebsiteSharedWithCurrentUser";
import { getCurrentStoreData } from "../../store/websites/thunks";
import { Button, Input } from "antd";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize
);

interface Props {
  id: string;
  name: string;
  onChange: (files: any) => void;
  value?: string;
  onUploadComplete: (response: any) => void;
  className?: string;
  auth: AuthState;
  user: UserState;
  plans: PlansState;
  sharedAccesses: SharedAccessesState;
  getCurrentStoreData: GetCurrentStoreData;
}

const Uploader: React.FC<Props> = ({
  id,
  name,
  onChange,
  value,
  onUploadComplete,
  className,
  auth,
  getCurrentStoreData,
  user,
  plans,
  sharedAccesses,
}) => {
  const pondRef = useRef<FilePond>(null);
  const [currentImgUrl, setCurrentImgUrl] = useState("")

  useEffect(() => {
    if (pondRef.current && value) {
      pondRef.current.addFile(value);
    }
  }, [value]);

  const { currentWebsite } = getCurrentStoreData();
  const websiteId = _.get(currentWebsite, "id");
  const isProPlan = checkIsProPlan(user, plans);
  const isShared = isWebsiteSharedWithCurrentUser(
    sharedAccesses,
    websiteId,
    user.data.email
  ).isTrue;

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (!currentImgUrl) return;
    onUploadComplete({
      url: currentImgUrl
    });
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
        width: "100%",
        flexDirection: "column",
      }}
      className="image-picker__filepond"
    >
      <FilePond
        ref={pondRef}
        allowMultiple={false}
        credits={false}
        acceptedFileTypes={["image/*"]}
        labelFileTypeNotAllowed="Only images are allowed"
        maxFileSize={isProPlan || isShared ? "5MB" : "1MB"}
        labelMaxFileSizeExceeded="File is too large"
        server={{
          url: `${apiUrlBase}/api/v1/images/user_upload_to_s3?file_key=${name}&website_id=${websiteId}`,
          headers: {
            Authorization: `Token ${auth.accessToken}`,
          },
          // @ts-ignore
          process: {
            method: "POST",
            onload: (response) => {
              const responseData = JSON.parse(response).file;
              const uploadedImgSrc = responseData.url;
              if (uploadedImgSrc) {
                onUploadComplete(responseData);
              } else {
                openNotification(
                  "Error :(",
                  "There was an unknown error during uploading the file. Please refresh the page and try again.",
                  "OK",
                  "warn"
                );
              }
              return responseData;
            },
            onerror: (error) => {
              console.error("FilePond error:", error);
              let errorJson: any = {};
              let message =
                "There was an error during file upload. Please try again.";
              try {
                errorJson = JSON.parse(error);
                message = errorJson.message || errorJson.detail;
              } catch (e) {
                console.error("Error parsing error:", e);
              }
              openNotification("Error", message, "OK", "error");
            },
          },
        }}
        name={name}
        id={id}
        className={className}
        onupdatefiles={(fileItems) => {
          const files = fileItems.map((fileItem) => fileItem.file);
          onChange(files.length > 0 ? files[0] : null);
        }}
        labelIdle="Upload image"
      />
      <>
        <span style={{ color: "#595959", fontSize: "12px" }}>or</span>
        <form style={{display: "flex", gap: 10, width: "100%"}} onSubmit={handleOnSubmit}>
          <Input
            style={{ width: "100%" }}
            placeholder="Enter image URL"
            onChange={(e) => {
              setCurrentImgUrl(e.target.value);
            }}
            value={currentImgUrl}
          />
          {!!currentImgUrl && <Button type="primary" icon="save" style={{flexShrink: 0}} htmlType="submit"/>}
        </form>
      </>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.user,
  plans: state.plans,
  sharedAccesses: state.sharedAccesses,
});

export default connect(mapStateToProps, { getCurrentStoreData })(Uploader);
